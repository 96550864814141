import React, { useState } from 'react';
import './App.scss';
// import { Helmet } from 'react-helmet';
import DesktopApp from './DesktopApp';
import MobileApp from './MobileApp';
import { Helmet as HelmetOriginal } from 'react-helmet';

function App() {
    const mmIsMobile = window.matchMedia('(max-width: 600px)');
    const Helmet: any = HelmetOriginal;
    const [isMobile, setIsMobile] = useState(mmIsMobile.matches);

    mmIsMobile.addEventListener('change', mm => {
        setIsMobile(mm.matches);
    });

    return (
        <>
            <Helmet>
                <title>Lowe&apos;s: Media, Remodeled</title>
            </Helmet>
            { isMobile ? <MobileApp/> : <DesktopApp/> }
        </>
    );
}

export default App;
